html, body {
    background-color: #FBF3EB;
    height: 100%;
    overflow: hidden;
}

:root {
    --blue: #2A3497;
    --white: #FBF3EB;
}

.hovercolor {
    border-color: rgba(255, 255, 255, 0);
    transition: 200ms ease-in-out;
}

.hovercolor:hover {
    border-color: var(--blue);
}

.headerbar {
    transition: ease-in-out 200ms;
}

.headerbar:hover {
    background-color: #FBF3EB;
}

.tags div:nth-child() {
    animation: driftinleft 200ms ease-out;
}

.objectdrift {
    object-position: 50% 50%;
    animation: objdrift 5000ms ease-out;
}

#main-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
}

.locked {
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    pointer-events: none;
}

.keplermed {
    font-family: kepler-std-extended, serif;
    font-weight: 500;
    font-style: italic;
}

.keplersemi {
    font-family: kepler-std-extended, serif;
    font-weight: 600;
    font-style: italic;
}

.keplerblack {
    font-family: kepler-std-extended, serif;
    font-weight: 900;
    font-style: normal;
}

.sandoll {
    font-family: sandoll-andante, sans-serif;
    font-weight: 400;
    font-style: normal;
}

.sandy {
    background-image: url('./images/sand.png');
    background-size: cover;
}

.scrollHide::-webkit-scrollbar {
    display: none;
}
  
.scrollHide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
  

p {
    font-size: 16px;
    font-family: kepler-std-extended, serif;
    font-weight: 400;
    font-style: normal;
    hyphens: auto;
    hyphenate-limit-chars: 8;
}

@media screen and (max-width: 1680px) {
    p {
        font-size: calc(12px + 0.1vw);
    }
 }
 
@media screen and (max-width: 800) {
    p {
        font-size: 14px;
    }
 }

@media screen and (max-width: 600) {
    p {
        font-size: 10px;
    }
 }

::-webkit-scrollbar {
    position: absolute;
    width: 0.5rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--blue);
    position: absolute;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--blue);
    position: absolute;
}

::selection {
    background: rgba(42, 52, 151, 0.3);
    position: absolute;
}

mark {
    font-family: kepler-std-extended, serif;
    font-weight: 600;
    font-style: normal;
    background: none;
    color: var(--blue);
}

.driftinslow {
    animation: driftinslow 1500ms ease-in-out;
}

.driftinfast {
    animation: driftinfast 1500ms ease-in-out;
}

.driftinleft {
    animation: driftinleft 1500ms ease-in-out;
}

.fadein {
    animation: fadein 1000ms ease-in-out;
}

.rotate {
    animation: rotate 25s linear infinite;
}

.scroll {
    animation: animate_text 60s linear infinite;
}

@media(prefers-reduced-motion) {
    .driftinslow {
        animation: none;
    }
    
    .driftinfast {
        animation: none;
    }
    
    .driftinleft {
        animation: none;
    }
    
    .fadein {
        animation: none;
    }
    
    .rotate {
        animation: none;
    }
}

@keyframes animate_text {
    from {
      transform: translate(0, 0);
    }
    to {
      transform: translate(-100%, 0);
    }
}

@keyframes objdrift {
    0% {
      object-position: 0% 0%;
    }
    100% {
        object-position: 50% 50%;
    }
}

@keyframes driftinfast {
    0% {
        /* translate: 0px -100px; */
        opacity: 0%;
    }

    50% {
        translate: 0px 0px;
        opacity: 100%;
    }


    100% {
        translate: 0px 0px;
        opacity: 100%;
    }
}

@keyframes driftinslow {
    0% {
        /* translate: 0px -100px; */
        opacity: 0%;
    }

    75% {
        translate: 0px 0px;
    }
}

@keyframes driftinleft {
    0% {
        translate: -3rem 0rem;
        opacity: 0%;
    }

    75% {
        translate: 0rem 0rem;
    }
}

@keyframes fadein {
    0% {
        opacity: 0%;
    }

    100% {
        opacity: 100%;
    }
}

@keyframes rotate {
    0% {
        rotate: 0deg;
    }

    100% {
        rotate: -360deg;
    }
}